import 'webticker/jquery.webticker.min.js'



$("#webticker-home-commodities").webTicker({
    speed: 120, // Pixels per second
    duplicate: true,  // determines if tickers should wrap around before one lap ends
    height: '100%',
});



